import {DefaultPrivacyLevel} from "@datadog/browser-rum";

enum Site {
  US1 = 'datadoghq.com'
}
type ConsoleApiName = 'log' | 'debug' | 'info' | 'warn' | 'error';

export const environment = {
  production: false,

  //Endpoints
  playerCompeteMobile: 'https://m.devcompete.fullswingapps.com',
  apiLeagueServer: 'https://devcompeteapi.fullswingapps.com/api',
  apiBaymanagerServer: 'https://devlauncher.fsgapi.net/api/fs',
  apiBaymanagerPanel: 'https://devbaymanager.fsgapi.net',
  authPortal: 'https://devauth.fullswingapps.com',
  hostName: 'devbaymanager.fsgapi.net',

  //Variables
  defaultPage: 'home',
  navName: '_fs_nav_name',

  //Objects
  breakPointScreen: {
    small: 576,
    Medium: 768,
    large: 992,
    extraLarge: 1200,
    xExtraLarge: 1400,
  },

  //Ploicy groups
  companyAdmin: 'companyadmin',
  competeAdmin: 'competeadmin',
  bayManagerAdmin: 'baymanageradmin',
  competePlayer: 'competeplayer',
  adminPortal: 'techadmin',
  residential: 'Users',

  // datadog
  datadog: {
    enableDatadogLogs: false,
    enableDatadogRum: false,
    rumConfig: {
      applicationId: '555b97cb-3bc9-4219-bd0d-96d418904d78',
      clientToken: 'pubdc8b59791bf0cba9ae123dd525e0274a',
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: Site.US1,
      service: 'fs-compete-website',
      env: 'dev',
      // Specify a version number to identify the deployed version of your application in Datadog
      version: '1.1.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      defaultPrivacyLevel: DefaultPrivacyLevel.ALLOW,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      // currently tracing not allowed in dev because server needs to be configured
      //allowedTracingUrls: ["https://devleagues.fullswingapps.com"]
    },
    // make the forwardConsoleLogs only to "warn" using the ConsoleApiName[] required


    logsConfig: {
      clientToken: 'pubdc8b59791bf0cba9ae123dd525e0274a',
      site: Site.US1,
      service: 'fs-compete-website',
      env: 'dev',
      version: '1.1.0',
      forwardErrorsToLogs: true,
      forwardConsoleLogs: [ 'warn', 'error'] as ConsoleApiName[],
      sessionSampleRate: 100,
    }
  }

};
