import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { AppModule } from './app/app.module';

import awsconfig from './aws-exports';
import { Amplify } from 'aws-amplify';
import { environment } from './environments/environment';
import { replaceBaseUrl } from './app/shared/helper-functions';
import { datadogRum } from '@datadog/browser-rum';
import {datadogLogs} from "@datadog/browser-logs";

Amplify.configure(awsconfig);

// Initialize Datadog Real User Monitoring
if (environment.datadog.enableDatadogRum) {

  datadogRum.init(environment.datadog.rumConfig);
}

// Initialize Datadog Logs
if (environment.datadog.enableDatadogLogs) {
  datadogLogs.init(environment.datadog.logsConfig);
}

if (environment.production) {
  enableProdMode();
}

if (window.screen.width < environment.breakPointScreen.Medium) {
  const url = window.location.href.toLowerCase();

  if (url.search('type=1') > 0) {
    window.location.href = replaceBaseUrl(environment.authPortal);
  } else if (url.search('type=2') > 0) {
    window.location.href = replaceBaseUrl(environment.authPortal, false);
  } else {
    window.location.href = replaceBaseUrl(environment.playerCompeteMobile);
  }
} else {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}
